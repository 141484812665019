import { Input, Output, Component, OnInit, Inject, ViewChild, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { NatureSystemModel, NatureSeedingMethod, GabionSystemModel, BlockOptions, GabionConstructionMethod,
  GabionSubsystem, Product
} from "../calculationModel/calculationEnums";
import { InputModel } from "../calculationModel/inputModel";
import { IProductPricedetails } from "../calculationModel/IProductPricedetails";
import { PricesService } from "../prices.service";
import { IConfigurationGroup } from "../calculationModel/configurationGroup";
import { ConfigurationService } from "../configuration.service";
import { IConfigurationTitle } from "../calculationModel/configurationTitle";
import { InputModelMapper } from "../calculationModel/inputModelMapper";
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute, UrlSerializer } from '@angular/router';
import { NgbNav, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
//import { UnitSystem, UnitType } from '../calculationModel/unitSystem';

import { DOCUMENT } from '@angular/common';
import { SubSystemMapper } from "../calculationModel/subSystemMapper";
import { UserService } from "../user.service";
import { SignInComponent } from '../sign-in/sign-in.component';
import { Subscription } from 'rxjs';
import { AuthService } from '../authService/auth.service';
import { UserRoles } from '../user-roles.config';
import { ClipboardService } from 'ngx-clipboard'
import { countries } from "../calculationModel/country-data-store";



@Component({
  selector: 'app-user-input',
  templateUrl: './user-input.component.html',
  styleUrls: ['./user-input.component.css'],
  //changeDetection: ChangeDetectionStrategy.OnPush, 
})
export class UserInputComponent implements OnInit {

  @ViewChild('nav', { static: true }) ngbNav: NgbNav;
  @ViewChild(SignInComponent, { static: true }) signInComponent: SignInComponent;
  isLoggedIn: boolean;
  isLoggedInSubscription: Subscription;
  roleSubscription: Subscription;

  userRoles = UserRoles;

  availableConfigGroups: IConfigurationGroup[];
  availableCalculations: IConfigurationTitle[];
  //currentCalculationCode: string;
  codeLoaded:boolean;
  currentCalculationCode: FormControl;
  canSaveConfig: boolean;
  dataPrivacyAccepted: boolean = false;

  public countries: any = countries;

  selectConfigForm = new FormGroup({
    availableGroups: new FormControl(),
    selectedGroup: new FormControl(),
    selectedCalculation: new FormControl(),
    newSubCalcName: new FormControl()
  });

  columnDefs: any;
  rowData: any;
  calcResult: any;
  productPrices: IProductPricedetails[];

  contact = new FormGroup({});
  generalInput = new FormGroup({});
  systemConfig = new FormGroup({});
  inputSafety = new FormGroup({});
  inputSoilParameterForm = new FormGroup({});
  inputMainFieldsForm = new FormGroup({});

  form = new FormArray([this.contact, this.generalInput, this.systemConfig, this.inputSafety, this.inputSoilParameterForm, this.inputMainFieldsForm]);

  //form = new FormGroup({
  //  contact: this.contact,
  //  generalInput: this.generalInput,
  //  systemConfig: this.systemConfig,
  //  inputSafety: this.inputSafety,
  //  inputSoilParameterForm: this.inputSoilParameterForm,
  //  inputMainFieldsForm: this.inputMainFieldsForm
  //});
  inputModel: InputModel;
  modalRef: BsModalRef;

  subSystemModalRef: BsModalRef;

  contactOptions: FormlyFormOptions = {
    formState: {
      disabled: true
    },
  };

  inputSoilParameter: FormlyFieldConfig[] = [
    {
      key: 'frictionAngleCharacteristic',
      type: 'input',
      templateOptions: {
        translate: true,
        type: 'number',
        label: 'input.frictionAngle',
      },
      modelOptions: {
        updateOn: 'blur',
      },
      validators: {
        between20and45: {
          expression: (control) => control.value !== null && control.value !== undefined && control.value >= 25 && control.value <= 45,
          message: (error, field: FormlyFieldConfig) => this.translate.get('input.validationBetween20and45')
        },
      }
    },
    {
      key: 'unitWeightCharacteristic',
      type: 'input',
      templateOptions: {
        translate: true,
        type: 'number',
        label: 'input.unitWeightCharacteristic',
      },
      modelOptions: {
        updateOn: 'blur',
      },
      validators: {
        between17and23: {
          expression: (control) => {
            const isImp = control._fields[0].templateOptions.label.endsWith('[lb/ft³]');
            return control.value !== null &&
              control.value !== undefined &&
              control.value >= (isImp ? 108 : 17) &&
              control.value <= (isImp ? 147 : 23);
          },
          message: (error, field: FormlyFieldConfig) => this.translate.get('input.validationBetween17and23')
        },
      }
    },
    {
      key: 'phValueOfFillingSoil',
      type: 'select',
      templateOptions: {
        translate: true,
        label: 'input.phOptionFieldLabel',
        options: [],
      },
    },

    {
      key: 'designLife',
      type: 'select',
      templateOptions: {
        translate: true,
        label: 'input.designLifeFieldLabel',
        options: [],
        //required: true,
      }
    }
  ];

  inputSafetyFactor: FormlyFieldConfig[] = [
    {
      key: 'frictionAngle',
      type: 'input',
      templateOptions: {
        translate: true,
        type: 'number',
        label: 'input.frictionAngleFieldLabel',
      },
      modelOptions: {
        updateOn: 'blur',
      },
      validators: {
        between1and125: {
          expression: (control) => control.value !== null && control.value !== undefined && control.value >= 1 && control.value <= 1.25,
          message: (error, field: FormlyFieldConfig) => this.translate.get('input.validationBetween1and125')
        },
      }
    },
    {
      key: 'unitWeight',
      type: 'input',
      templateOptions: {
        translate: true,
        type: 'number',
        label: 'input.unitWeightFieldLabelPartialSafetyFactor',
      },
      modelOptions: {
        updateOn: 'blur',
      },

      validators: {
        between1and15: {
          expression: (control) => control.value !== null && control.value !== undefined && control.value >= 1 && control.value <= 1.5,
          message: (error, field: FormlyFieldConfig) => this.translate.get('input.validationBetween1and15')
        },
      }
    },
    {
      key: 'trafficLoad',
      type: 'input',
      templateOptions: {
        translate: true,
        type: 'number',
        label: 'input.trafficLoadFieldLabelPartialSafety',
      },
      modelOptions: {
        updateOn: 'blur',
      },
      validators: {
        between1and15: {
          expression: (control) => control.value !== null && control.value !== undefined && control.value >= 0 && control.value <= 52,
          message: (error, field: FormlyFieldConfig) => this.translate.get('input.validationBetween0and52kn')
        },
      }
    },
    {
      key: 'materialPartialSafetyFactor',
      type: 'input',
      templateOptions: {
        translate: true,
        type: 'number',
        label: 'input.materialPartialSafetyFactorFieldLabel',
      },
      modelOptions: {
        updateOn: 'blur',
      },
      validators: {
        between1and14: {
          expression: (control) => control.value !== null && control.value !== undefined && control.value >= 1 && control.value <= 1.4,
          message: (error, field: FormlyFieldConfig) => this.translate.get('input.validationBetween1and14')
        },
      }
    }

    ];

  fillingSoilFields: FormlyFieldConfig[] = [
    {
      key: 'frictionAngleCharacteristic',
      type: 'input',
      templateOptions: {
        translate: true,
        type: 'number',
        label: 'input.frictionAngleCharacteristicFieldLabel',
        //placeholder: 'Enter email',
        //required: true,
        //change: this.calculateInput.bind(this)
      },
      modelOptions: {
        updateOn: 'blur',
      },
      validators: {
        between20and45: {
          expression: (control) => control.value !== null && control.value !== undefined && control.value >= 20 && control.value <= 45,
          message: (error, field: FormlyFieldConfig) => this.translate.get('input.validationBetween20and45')
        },
      }
    },
    {
      key: 'frictionAngleCharacteristicRad',
      type: 'input',
      templateOptions: {
        label: '[rad]',
        type: 'number',
        //placeholder: 'Enter email',
        required: true,
        readonly: true,
      },
      modelOptions: {
        updateOn: 'blur',
      },
      //expressionProperties: {
      //  'templateOptions.disabled': 'true',
      //},
    },
    {
      key: 'frictionAngleDesign',
      type: 'input',
      templateOptions: {
        type: 'number',
        label: 'friction angle, design',
        //placeholder: 'Enter email',
        required: true,
      },
      modelOptions: {
        updateOn: 'blur',
      },
      //expressionProperties: {
      //  'templateOptions.disabled': 'true',
      //},
    },
    {
      key: 'frictionAngleDesignRad',
      type: 'input',
      templateOptions: {
        label: '[rad]',
        type: 'number',
        //placeholder: 'Enter email',
        required: true,
      },
      modelOptions: {
        updateOn: 'blur',
      },
      //expressionProperties: {
      //  'templateOptions.disabled': 'true',
      //},
    },
    {
      key: 'unitWeightCharacteristic',
      type: 'input',
      templateOptions: {
        hidden: true,
        translate: true,
        type: 'number',
        label: 'input.unitWeightCharacteristicFieldLabel',
        //placeholder: 'Enter email',
        //required: true,
        //change: this.calculateInput.bind(this)
      },
      modelOptions: {
        updateOn: 'blur',
      },
      validators: {
        between17and23: {
          expression: (control) => control.value !== null && control.value !== undefined && control.value >= 17 && control.value <= 23,
          message: (error, field: FormlyFieldConfig) => this.translate.get('input.validationBetween17and23')
        },
      }
    },
    {
      key: 'unitWeightDesign',
      type: 'input',
      templateOptions: {
        translate: true,
        type: 'number',
        label: 'input.unitWeightDesignFieldLabel',
        //placeholder: 'Enter email',
        required: true,
        hidden: true
      },
      expressionProperties: {
        'templateOptions.disabled': 'true',
      },
    },
    {
      key: 'phValueOfFillingSoil',
      type: 'select',
      templateOptions: {
        hidden: true,
        label: 'input.phValueFillingSoilFieldLabel',
        translate: true,
        options: [
        ],
        //modelOptions: {
        //  updateOn: 'debounce',
        //},
        //required: true,
        //change: this.phChanged.bind(this)
      },
      modelOptions: {
        //updateOn: 'blur',
      },

    },
    {
      key: 'productString',
      type: 'input',
      templateOptions: {
        hidden: true,
        label: 'Product',
        required: true,
        readonly: true,
      },
      modelOptions: {
        updateOn: 'blur',
      },
    }
  ];
  surchargeFields: FormlyFieldConfig[] = [
    {
      key: 'uniformVerticalSurcharge',
      type: 'input',
      templateOptions: {
        label: 'input.uniformverticalsurchargeFieldLabel',
        translate: true,
        //required: true,
        //change: this.calculateInput.bind(this)
      },
      modelOptions: {
        updateOn: 'blur',
      },
      validators: {
        between0and52: {
          expression: (control) => control.value !== null && control.value !== undefined && control.value >= 0 && control.value <= 52,
          message: (error, field: FormlyFieldConfig) => this.translate.get('input.validationBetween0and52kn')
        },
      }
    },
    {
      key: 'uniformVerticalSurchargeDesignValue',
      type: 'input',
      templateOptions: {
        type: 'number',
        label: 'uniform vertical surcharge, design value',
        required: true,
      },
      modelOptions: {
        updateOn: 'blur',
      },
      expressionProperties: {
        'templateOptions.disabled': 'true',
      },
    },
    {
      key: 'effectiveHeight',
      type: 'input',
      templateOptions: {
        type: 'number',
        label: 'effective height',
        required: true,
      },
      modelOptions: {
        updateOn: 'blur',
      },
      expressionProperties: {
        'templateOptions.disabled': 'true',
      },
    },
    {
      key: 'widthOfUniformVerticalSurcharge',
      type: 'input',
      templateOptions: {
        type: 'number',
        label: 'input.widthofuniformverticalsurchargeFieldLabel',
        translate: true,
        required: true,
        //change: this.calculateInput.bind(this)
      },
      modelOptions: {
        updateOn: 'blur',
      },
    },
    {
      key: 'angleOfInclinationOfSlipSurfaces',
      type: 'input',
      templateOptions: {
        type: 'number',
        label: 'angle of inclination of slip surfaces',
        required: true,
      },
      modelOptions: {
        updateOn: 'blur',
      },
      expressionProperties: {
        'templateOptions.disabled': 'true',
      },
    },
    {
      key: 'angleOfInclinationOfSlipSurfacesRad',
      type: 'input',
      templateOptions: {
        type: 'number',
        label: '[rad]',
        required: true,
      },
      modelOptions: {
        updateOn: 'blur',
      },
      expressionProperties: {
        'templateOptions.disabled': 'true',
      },
    },
    {
      key: 'influencingDepthOfVerticalSurcharge',
      type: 'input',
      templateOptions: {
        type: 'number',
        label: 'influencing depth of vertical surcharge',
        required: true,
      },
      modelOptions: {
        updateOn: 'blur',
      },
      expressionProperties: {
        'templateOptions.disabled': 'true',
      },
    },
  ];

  geometryFields: FormlyFieldConfig[] = [
    {
      key: 'height',
      type: 'input',
      templateOptions: {
        type: 'number',
        label: 'height',
        //required: true,
        //change: this.calculateInput.bind(this)
      },
      modelOptions: {
        updateOn: 'blur',
      },
      validators: {
        between1and10: {
          expression: (control) => control.value !== null && control.value !== undefined && control.value >= 1 && control.value <= 10,
          message: (error, field: FormlyFieldConfig) => this.translate.get('input.validationBetween1And10')
        },
      }
    },
    {
      key: 'slopeAngle',
      type: 'input',
      templateOptions: {
        type: 'number',
        label: 'slope angle',
        //required: true,
        //change: this.calculateInput.bind(this)
      },
      modelOptions: {
        updateOn: 'blur',
      },
      validators: {
        between45and90: {
          expression: (control) => control.value !== null && control.value !== undefined && control.value >= 45 && control.value <= 90,
          message: (error, field: FormlyFieldConfig) => this.translate.get('input.validationBetween45and90')
        },
      }
    },
    {
      key: 'slopeAngleDegreeFirst',
      type: 'input',
      templateOptions: {
        type: 'number',
        label: '[°]',
        required: true,
      },
      modelOptions: {
        updateOn: 'blur',
      },
      expressionProperties: {
        'templateOptions.disabled': 'true',
      },
    },
    {
      key: 'slopeAngleDegreeSecond',
      type: 'input',
      templateOptions: {
        type: 'number',
        label: '[°]',
        required: true,
      },
      modelOptions: {
        updateOn: 'blur',
      },
      expressionProperties: {
        'templateOptions.disabled': 'true',
      },
    },
    {
      key: 'verticalLayerDistance',
      type: 'select',
      templateOptions: {
        label: 'input.verticalLayerDistanceFieldLabel',
        translate: true,
        options: [
        ],
        required: true,
        //change: this.calculateInput.bind(this)
      },
      modelOptions: {
        //updateOn: 'blur',
      },
    },
    {
      key: 'minOverburdenHeight',
      type: 'input',
      templateOptions: {
        label: 'input.minOverburdenHieghtFieldLabel',
        translate: true,
        type: 'number',

        required: true,
        //change: this.calculateInput.bind(this)
      },
      modelOptions: {
        updateOn: 'blur',
      },
    },
    {
      key: 'wallFriction',
      type: 'input',
      templateOptions: {
        label: 'wall friction',
        type: 'number',
        required: true,
        readonly: true,
        //change: this.calculateInput.bind(this)
      },
      modelOptions: {
        updateOn: 'blur',
      },
    },
    {
      key: 'wallFrictionRad',
      type: 'input',
      templateOptions: {
        label: '[rad]',
        type: 'number',
        required: true,
      },
      expressionProperties: {
        'templateOptions.disabled': 'true',
      },
      modelOptions: {
        updateOn: 'blur',
      },
    },
    {
      key: 'numberOfGeogrids',
      type: 'select',
      templateOptions: {
        label: 'input.numberOfGeogridsInOneBlockFieldLabel',
        translate: true,
        options: [
          { label: '3', value: 3 },
          { label: '4', value: 4 },
        ],
        required: true,
        readonly: true,
        //change: this.calculateInput.bind(this)
      },
      modelOptions: {
        //updateOn: 'blur',
      },
    }
  ];

  generalInputFields: FormlyFieldConfig[] = [
    //{
    //  key: 'frictionAngle',
    //  type: 'input',
    //  templateOptions: {
    //    hidden: true,
    //    type: 'number',
    //    label: 'input.frictionAngleTanjk',
    //    translate: true,
    //  },
    //  validators: {
    //    between1and125: {
    //      expression: (control) => control.value !== null && control.value !== undefined && control.value >= 1 && control.value <= 1.25,
    //      message: (error, field: FormlyFieldConfig) => this.translate.get('input.validationBetween1and125')
    //    },
    //  }
    //},
    //{
    //  key: 'unitWeight',
    //  type: 'input',
    //  templateOptions: {
    //    hidden: true,
    //    type: 'number',
    //    label: 'input.unitWeightFieldLabel2',
    //    translate: true,
    //    //required: true,
    //    //change: this.calculateInput.bind(this)
    //  },
    //  validators: {
    //    between1and15: {
    //      expression: (control) => control.value !== null && control.value !== undefined && control.value >= 1 && control.value <= 1.5,
    //      message: (error, field: FormlyFieldConfig) => this.translate.get('input.validationBetween1and15')
    //    },
    //  }
    //},
    //{
    //  key: 'trafficLoad',
    //  type: 'input',
    //  templateOptions: {
    //    hidden: true,
    //    type: 'number',
    //    label: 'input.trafficLoadFieldLabel',
    //    translate: true
    //    //required: true,
    //    //change: this.calculateInput.bind(this)
    //  },
    //  validators: {
    //    between1and15: {
    //      expression: (control) => control.value !== null && control.value !== undefined && control.value >= 1 && control.value <= 1.5,
    //      message: (error, field: FormlyFieldConfig) => this.translate.get('input.validationBetween1and15')
    //    },
    //  }
    //},
    //{
    //  key: 'materialPartialSafetyFactor',
    //  type: 'input',
    //  templateOptions: {
    //    hidden: true,
    //    type: 'number',
    //    label: 'input.materialPartialSafetyFactorFieldLabel',
    //    translate: true,
    //    //required: true,
    //    //change: this.calculateInput.bind(this)
    //  },
    //  validators: {
    //    between1and14: {
    //      expression: (control) => control.value !== null && control.value !== undefined && control.value >= 1 && control.value <= 1.4,
    //      message: (error, field: FormlyFieldConfig) => this.translate.get('input.validationBetween1and14')
    //    },
    //  }
    //},
    {
      key: 'verticalLayerDistance',
      type: 'select',
      templateOptions: {
        label: 'input.verticalLayerDistanceFieldLabel',
        translate: true,
        options: [
        ],
        required: true,
        //change: this.calculateInput.bind(this)
      },
      modelOptions: {
        //updateOn: 'blur',
      },
    },
    {
      key: 'grainSizeDistribution',
      type: 'select',
      templateOptions: {
        translate: true,
        label: 'input.grainSizeDistributionFieldLabel',
        options: [
        ],
        required: true,
      }
    },
    {
      key: 'petPva',
      type: 'radio',
      templateOptions: {
        label: 'rawMaterialInputLabel',
        translate: true,
        options: [
          { label: "PET", value: 1 }, //Cheap
          { label: "PVA", value: 2 }  //Expensive
        ],
        change: () => {
          const isAlkaline = this.inputModel.phValueOfFillingSoil === 3;
          const isPET = this.inputModel.petPva === 1;

          if (isAlkaline && isPET) {
            alert("PET can only be used for acidic and neutral fill materials. Currently alkaline is selected. Selection will be changed to 'Neutral'");
            const optionField = this.inputSoilParameter.find(x => x.key === 'phValueOfFillingSoil');
            optionField.formControl.setValue(2);
          }

        }
      },
    },
    //{
    //  key: 'designLife',
    //  type: 'select',
    //  templateOptions: {
    //    hidden: true,
    //    label: 'input.designLifeFieldLabel',
    //    translate:true,
    //    options: [],
    //    required: true,
    //    //change: this.calculateInput.bind(this)
    //  }
    //}
  ];
  
  systemConfigFields: FormlyFieldConfig[] = [
    {
      key: 'priceId',
      type: 'select',
      templateOptions: {
        label: 'input.PricelistLabel',
        translate: true,
        options: this.priceService.prices,
        valueProp: 'id',
        labelProp: 'name',
      },
      modelOptions: {
        //updateOn: 'blur',
      },
    },
    {
      key: 'systemModelNature',
      type: 'select',
      templateOptions: {
        label: 'input.systemModelFieldLabel',
        translate: true,
        options: [
        ]
      },
      modelOptions: {
        //updateOn: 'blur',
      },
    },
    {
      key: 'seedingMethod',
      type: 'select',
      templateOptions: {
        label: 'input.seedingMethodFieldLabel',
        translate: true,
        options: [
        ]
      },
      modelOptions: {
        //updateOn: 'blur',
      },
    },
    {
      key: 'systemModelGabion',
      type: 'select',
      templateOptions: {
        label: 'input.systemModelGabionFieldLabel',
        translate: true,
        options: []
      },
      modelOptions: {
        //updateOn: 'blur',
      },
    },
    {
      key: 'constructionMethodGabion',
      type: 'select',
      hideExpression: (model: InputModel, formState: any, field: FormlyFieldConfig) => {
        return model.systemModelGabion !== GabionSystemModel.TieRodSystem;
      },
      templateOptions: {
        label: 'input.gabionConstructionFieldLabel',
        translate: true,
        options: [
        ]
      },
      modelOptions: {
        //updateOn: 'blur',
      },
    },
    {
      key: 'subsystemGabion',
      type: 'select',
      hideExpression: (model: InputModel, formState: any, field: FormlyFieldConfig) => {
        return model.systemModelGabion !== GabionSystemModel.TieRodSystem;
      },
      templateOptions: {
        label: 'input.fortracGabionSubsystemFieldLabel',
        translate: true,
        options: [
          { label: "System 1", value: GabionSubsystem.T1Dach },
          { label: "System 2", value: GabionSubsystem.T2EEMEA },
        ]
      },
      modelOptions: {
        //updateOn: 'blur',
      },
    },
    {
      key: 'systemModelBlock',
      type: 'select',
      templateOptions: {
        label: 'input.blockFacingFieldLabel',
        translate: true,
        options: []
      },
      modelOptions: {
        //updateOn: 'blur',
      },
    },
    ];

  inputMainFields: FormlyFieldConfig[] = [
    {
      key: 'role',
      type: 'select',
      templateOptions: {
        translate: true,
        label: 'input.role',
        options: [
        ]
      },
      hide: true,
    },

    //{
    //  key: 'unitSystem',
    //  type: 'radio',
    //  templateOptions: {
    //    label: 'input.unitSystem',
    //    translate: true,
    //    //required: true,
    //    options: [{ value: "Metric", key: 1 }, { value: "Imperial", key: 2 }],
    //    //required: true,
    //    //change: this.calculateInput.bind(this)

    //  },
    //  modelOptions: {
    //    //updateOn: 'blur',
    //  },
    //  //validators: {
    //  //  between0and52: {
    //  //    expression: (control) => control.value !== null && control.value !== undefined && control.value >= 0 && control.value <= 52,
    //  //    message: (error, field: FormlyFieldConfig) => this.translate.instant('input.validationBetween0and52kn')
    //  //  },
    //  //}
    //},

    //{
    //  key: 'language',
    //  type: 'radio',
    //  templateOptions: {
    //    label: 'Lang',
    //    options: [
    //      { label: 'de', value: 'de' },
    //      { label: 'en', value: 'en' },
    //    ],
    //    change: (field, event) => {
    //      const language = (<any>field.form.controls).language.value;
    //      this.translate.use(language);
    //    }
    //    //change:this.changeLang.bind(this)
    //  },
    //},
    {
      key: 'title',
      type: 'input',
      templateOptions: {
        label: 'input.titleFieldLabel',
        translate: true,
        type: 'text'
      },
      modelOptions: {
        updateOn: 'blur',
      },
      //expressionProperties: {
      //  'templateOptions.disabled': 'model.isDefault',
      //},
    },
    {
      key: 'selectedSystem',
      type: 'select',
      templateOptions: {
        label: 'systemLabel',
        translate: true,
        options: [
          { label: 'Fortrac Nature', value: Product.Nature },
          { label: 'Fortrac Gabion', value: Product.Gabion },
          { label: 'Fortrac Block', value: Product.Block },
          { label: 'Fortrac Panel', value: Product.Panel },
        ]
      }
    },
    {
      key: 'height',
      type: 'input',
      templateOptions: {
        type: 'number',
        label: 'input.heightOfWallFieldLabel',
        translate: true,
      },
      modelOptions: {
        updateOn: 'blur',
      },
      expressionProperties: {
        //'templateOptions.label': this.translate.stream('input.heightOfWallFieldLabel', { 'unit': this.getUnitLabel(UnitType.Length) }),
        //'templateOptions.disabled': 
      },
      //expressionProperties: {
      //  'templateOptions.disabled': 'model.isDefault',
      //},
      validators: {
        between1and10: {
          expression: (control) => {
            const isImp = control._fields[0].templateOptions.label.endsWith('[ft]');
            return control.value !== null &&
              control.value !== undefined &&
              control.value >= (isImp ? 3 : 1) &&
              control.value <= (isImp ? 33 : 10);
          },
          message: (error, field: FormlyFieldConfig) => {
            return this.translate.instant('input.validationBetween1And10');
          },
        },
      }
    },

    {
      key: 'slopeAngle',
      type: 'input',
      templateOptions: {
        type: 'number',
        label: 'input.slopeAngleFieldLabel',
        translate: true,
      },
      modelOptions: {
        updateOn: 'blur',
      },
      validators: {
        between45and90: {
          expression: (control) => control.value !== null && control.value !== undefined && control.value >= 45 && control.value <= 90,
          message: (error, field: FormlyFieldConfig) => {
            if (this.inputModel.selectedSystem === Product.Panel) {
              return this.translate.get("input.validationBetween80And90");
            } else {
              return this.translate.get('input.validationBetween45and90');
            }
          }
        }
      }
      //expressionProperties: {
      //  'templateOptions.disabled': 'model.isDefault',
      //},
    },
    {
      key: 'uniformVerticalSurcharge',
      type: 'input',
      templateOptions: {
        type: 'number',
        label: 'input.uniformverticalsurchargeFieldLabel',
        translate: true,
        //required: true,
        //change: this.calculateInput.bind(this)
      },
      modelOptions: {
        updateOn: 'blur',
      },
      validators: {
        between0and52: {
          expression: (control) => {
            const isImp = control._fields[0].templateOptions.label.endsWith('[psi]');
            return control.value !== null &&
              control.value !== undefined &&
              control.value >= 0 &&
              control.value <= (isImp ? 8 : 52);
          },
          message: (error, field: FormlyFieldConfig) => this.translate.instant('input.validationBetween0and52kn')
        },
      }
    },
    {
      key: 'shouldAllGeoGridsHaveSameLength',
      type: 'radio',
      templateOptions: {
        label: 'input.shouldAllGeoGridsHaveSameLength',
        translate: true,
        options: [
          { label: "EVP", value: 1 },
          { label: "PET", value: 2}
        ]
      },
    },
    ];

  inputContactData: FormlyFieldConfig[] = [
    //{
    //  key: 'moreInformationNeeded',
    //  type: 'checkbox',
    //  templateOptions: {
    //    label: 'input.moreInformationNeeded',
    //    translate: true,
    //    type: 'text'
    //  }
    //},
    {
      key: 'companyName',
      type: 'input',
      templateOptions: {
        label: 'input.companyNameLabel',
        translate: true,
        type: 'text'
      },
      modelOptions: {
        updateOn: 'blur',
      },
      expressionProperties: {
        'templateOptions.disabled': '!model.moreInformationNeeded',
      },
    },
    {
      key: 'contactName',
      type: 'input',
      templateOptions: {
        label: 'input.contactNameLabel',
        translate: true,
        type: 'text',
        required: true
      },
      modelOptions: {
        updateOn: 'blur',
      },
      expressionProperties: {
        'templateOptions.disabled': '!model.moreInformationNeeded',
      },
    },
    {
      key: 'contactLastName',
      type: 'input',
      templateOptions: {
        label: 'input.contactLastNameLabel',
        translate: true,
        type: 'text',
        required: true
      },
      modelOptions: {
        updateOn: 'blur',
      },
      expressionProperties: {
        'templateOptions.disabled': '!model.moreInformationNeeded',
      },
    },
    {
      key: 'contactCountry',
      type: 'select',
      templateOptions: {
        label: 'Country',
        //translate: true,
        options: this.countries.map(x => <any>{ label: x.name, value: x.name }),
      },
      modelOptions: {
        updateOn: 'blur',
      },
      expressionProperties: {
        'templateOptions.disabled': '!model.moreInformationNeeded',
      },
    },
    {
      key: 'emailAddress',
      type: 'input',
      templateOptions: {
        label: 'input.emailAddressLabel',
        translate: true,
        type: 'text',
        required: true,
      },
      modelOptions: {
        updateOn: 'blur',
      },
      expressionProperties: {
        'templateOptions.required': 'true',
      },
      validators: {
        ip: {
          expression: (c) => !c.value || /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(c.value),
          message: (error, field: FormlyFieldConfig) => this.translate.instant('input.notValidEmail')
        },
        //required: true
      },
    },
    {
      key: 'phoneNumber',
      type: 'input',
      templateOptions: {
        label: 'input.phoneNumberLabel',
        translate: true,
        type: 'text'
      },
      modelOptions: {
        updateOn: 'blur',
      },
      expressionProperties: {
        'templateOptions.disabled': '!model.moreInformationNeeded',
      },
      validators: {
        phone: {
          expression: (c) => !c.value || /^[+]\d+$/.test(c.value),
          message: (error, field: FormlyFieldConfig) => this.translate.instant('input.notValidPhone')
        },
        //required: true
      },
    },
    {
      key: 'userComment',
      type: 'textarea',
      templateOptions: {
        label: 'input.saveDialogUserCommentLabel',
        translate: true,
        type: 'text',
        rows: 4,
      },
      modelOptions: {
        updateOn: 'blur',
      },
      //expressionProperties: {
      //  'templateOptions.disabled': '!model.moreInformationNeeded',
      //},
    },
  ];

  subSystems = [];

  subSystemName:string = null;

  //getUnitLabel(type: UnitType) {

  //  const isMetric = this.inputModel?.unitSystem === UnitSystem.Metric;
  //  console.log('Get Unit label');
  //  switch(type)
  //  {
  //    case UnitType.Length:
  //      return isMetric ? "m" : "f";

  //  }

  //  return 'm';
  //}
  

  getSubsystemName(): string {
    switch (this.inputModel.selectedSystem) {
      case Product.Block:
        return this.inputModel.systemModelBlock ? this.inputModel.systemModelBlock.toString() : null;
      case Product.Gabion:
        return this.inputModel.systemModelGabion ? this.inputModel.systemModelGabion.toString() : null;
      case Product.Nature:
        return this.inputModel.systemModelNature ? this.inputModel.systemModelNature.toString() : null;
    }
    return null;
  }

  changeLang() {
    this.translate.use('de');
  }

  private setOptionsetLabels(): void {
    const isImp = this.inputModel.language === 'enimp';
    
    const verticalLayerDistanceOptions = [
      { label: isImp ? "16 inch" : "0,4m", value: 0.4},
      { label: isImp ? "20 inch" : "0,5m", value: 0.5},
      { label: isImp ? "24 inch" : "0,6m", value: 0.6},
      ];

    const optionFielVerticalLayerDistance = this.generalInputFields.find(x => x.key === 'verticalLayerDistance');
    optionFielVerticalLayerDistance.templateOptions.options = verticalLayerDistanceOptions;

    const phOptions = [
      {
        label: this.translate.instant('input.phOptionLabelAcidic'),
        value: 1
      },
      {
        label: this.translate.instant('input.phOptionLabelNeutral'),
        value: 2
      }, {
        label: this.translate.instant('input.phOptionLabelAlkaline'),
        value: 3
      }
    ];

    const optionField = this.inputSoilParameter.find(x => x.key === 'phValueOfFillingSoil');
    optionField.templateOptions.options = phOptions;

    const optionFieldphValueOfFillingSoil = this.fillingSoilFields.find(x => x.key === 'phValueOfFillingSoil');
    optionFieldphValueOfFillingSoil.templateOptions.options = phOptions;

    const designLifeOptions = [
      { label: this.translate.instant('input.designLifeOptionLabel1year'), value: 1 },
      { label: this.translate.instant('input.designLifeOptionLabel10Years'), value: 10 },
      { label: this.translate.instant('input.designLifeOptionLabel60years'), value: 60 },
      { label: this.translate.instant('input.designLifeOptionLabel120years'), value: 120 },
    ];

    //const generalInputFieldDesignLife = this.generalInputFields.find(x => x.key === 'designLife');
    //generalInputFieldDesignLife.templateOptions.options = designLifeOptions;

    const designLifeOptionField = this.inputSoilParameter.find(x => x.key === 'designLife');
    designLifeOptionField.templateOptions.options = designLifeOptions;

    const grainSizeDistributionOptions = [
      { label: this.translate.instant('input.grainSizeDistOptionLabelFine'), value: 1 },
      { label: this.translate.instant('input.grainSizeDistOptionLabelMix'), value: 2 },
      { label: this.translate.instant('input.grainSizeDistOptionLabelCourse'), value: 3 }

    ];

    const grainSizeDistOptionField = this.generalInputFields.find(x => x.key === 'grainSizeDistribution');

    grainSizeDistOptionField.templateOptions.options = grainSizeDistributionOptions;

    const systemModelOptions = [
      { label: this.translate.instant('input.systemModelOptionLabelWithoutIntegrated'), value: NatureSystemModel.WithoutIntegratedFormWork },
      { label: this.translate.instant('input.systemModelOptionLabelIntegratedLostFramework'), value: NatureSystemModel.IntegratedLostFormwork },
      { label: this.translate.instant('input.systemModelOptionLabelIntegratedCorrossion'), value: NatureSystemModel.IntegratedCorrosinProtectedFormWork }
    ];

    const systemModelOptionField = this.systemConfigFields.find(x => x.key === 'systemModelNature');
    systemModelOptionField.templateOptions.options = systemModelOptions;

    const seedingMethodOptions = [
      { label: this.translate.instant('input.seedingMethodOptionLabelNotNecessary'), value: NatureSeedingMethod.NotNecessary },
      { label: this.translate.instant('input.seedingMethodOptionLabelConventional'), value: NatureSeedingMethod.Conventional },
      { label: this.translate.instant('input.seedingMethodOptionLabelHydroseeding'), value: NatureSeedingMethod.HydroSeeding }
    ];

    const seedingMethodOptionField = this.systemConfigFields.find(x => x.key === 'seedingMethod');
    seedingMethodOptionField.templateOptions.options = seedingMethodOptions;

    const systemModelGabionOptions = [
      { label: this.translate.instant('input.systemModelGabionOptionLabelFullGabion'), value: GabionSystemModel.FullGabionFacing },
      { label: this.translate.instant('input.systemModelGabionOptionLabelHalfGabion'), value: GabionSystemModel.HalfGabionFacing },
      { label: this.translate.instant('input.systemModelGabionOptionLabelTieRod'), value: GabionSystemModel.TieRodSystem },
    ];

    const systemModelGabionOptionField = this.systemConfigFields.find(x => x.key === 'systemModelGabion');
    systemModelGabionOptionField.templateOptions.options = systemModelGabionOptions;


    const gabionConstructionOptions = [
      { label: this.translate.instant('input.gabionConstructionOptionLabelWithoutIntegrated'), value: GabionConstructionMethod.WithoutIntegratedFormwork },
      { label: this.translate.instant('input.gabionConstructionOptionLabelIntegrated'), value: GabionConstructionMethod.IntegratedLostFormwork },
    ];

    const gabionConstrcutionField = this.systemConfigFields.find(x => x.key === 'constructionMethodGabion');
    gabionConstrcutionField.templateOptions.options = gabionConstructionOptions;

    const blockFacingoptions = [
      { label: this.translate.instant('input.blockFacingOptionLabelHollow'), value: BlockOptions.HollowBlockFacing },
      { label: this.translate.instant('input.blockFacingOptionLabelSolid'), value: BlockOptions.SolidBlockFacing },
    ];

    const blockFacingOptionField = this.systemConfigFields.find(x => x.key === 'systemModelBlock');
    blockFacingOptionField.templateOptions.options = blockFacingoptions;

    const roleOptions = [
      { label: this.translate.instant('input.roleValueCustomer'), value: 'customer' },
      { label: this.translate.instant('input.roleValueInternal'), value: 'hueskerInternal' },
      { label: "Admin", value: 'admin' },
      { label: "Partner", value: 'partner' }
    ];

    const roleOptionField = this.inputMainFields.find(x => x.key === 'role');
    roleOptionField.templateOptions.options = roleOptions;

    const yesNoOptions =
      [{ label: this.translate.instant('yes'), value: 1 }, { label: this.translate.instant('no'), value: 0 }]
    ;

    const yesNoOptionField = this.inputMainFields.find(x => x.key === 'shouldAllGeoGridsHaveSameLength');
    yesNoOptionField.templateOptions.options = yesNoOptions;
  }


  initOptionLabelTranslation(): void {

    this.translate.onLangChange.subscribe(x => {
      this.translate.get('input.phOptionLabelAcidic').subscribe((res: string) => {
        this.setOptionsetLabels();
      });
    });

    this.translate.get('input.phOptionLabelAcidic').subscribe((res: string) => {
      this.setOptionsetLabels();
    });
  }

  reset(): void {
    console.log("Reset");
    this.currentCalculationCode.setValue(null);
    this.codeLoaded = false;

    //const isImp = this.inputModel.language === 'enimp';

    //const modelOptions = (<any>this.geometryFields.find(x => x.key === 'verticalLayerDistance')).templateOptions.options;

    //modelOptions.splice();

    //modelOptions.push({ label: (isImp ? '16 inch' : '0,40m'), value: (isImp ? 16 : 0.4) });
    //modelOptions.push({ label: (isImp ? '20 inch' : '0,50m'), value: (isImp ? 20 : 0.5) });
    //modelOptions.push({ label: (isImp ? '24 inch' : '0,60m'), value: (isImp ? 24 : 0.6) });

    this.inputModel = InputModelMapper.getDefaultInputModel(this.inputModel.language, this.inputModel.role);
    this.inputContactModelChange();
    this.currentCalculationCode.enable();
  }

  selectedSubSystemId:number;

  load(): void {
    const code = this.currentCalculationCode.value;
    this.configurationService.refreshCalculationByCode(code);
  }

  copyToClipboard(): void {

    const n = 1.1;
    const decimalSeperator = n.toLocaleString().substring(1, 2);

    const m = 1000;
    const thousandSeperator = m.toLocaleString().substring(1, 2);

    const res = this.rowData.reduce(function (acc, row) {
      const height : number  = row.height;
      const length: number = row.resultTotalLength;

      return `${acc}\r\n${row.layerIndex}\t${height.toLocaleString()}\t${row.productLine.label}\t${length.toLocaleString()}`;
    }, '');

    const length = res.length;

    this.clipboardService.copy(res.substring(2, length));

    const alertString = `Values were copied with ${decimalSeperator} as decimal seperator and ${thousandSeperator} as thousand seperator. (i.e. 1${thousandSeperator}000${decimalSeperator}50)`;

    alert(alertString);
  }

  setSubsystem(): void {
    let subSystem :string = null;
    switch (this.inputModel.selectedSystem) {
    case Product.Block:
        this.inputModel.systemModelBlock = SubSystemMapper.getBlockSubsystem(this.selectedSubSystemId);
        this.systemConfig.get('systemModelBlock')?.patchValue(this.inputModel.systemModelBlock);
        subSystem = this.inputModel.systemModelBlock.toString();
        
        break;
    case Product.Gabion:
        this.inputModel.systemModelGabion = SubSystemMapper.getGabionSubsystem(this.selectedSubSystemId);
        this.systemConfig.get('systemModelGabion')?.patchValue(this.inputModel.systemModelGabion);
        subSystem = this.inputModel.systemModelGabion.toString();
        break;
    case Product.Nature:
        this.inputModel.systemModelNature = SubSystemMapper.getNatureSubsystem(this.selectedSubSystemId);
        this.systemConfig.get('systemModelNature')?.patchValue(this.inputModel.systemModelNature);
        subSystem = this.inputModel.systemModelNature.toString();
        break;
    }

    this.checkAndSetSystemOrSubSystemChange(this.inputModel.selectedSystem.toString(), subSystem);

    this.subSystemName = this.getSubsystemName();
    
    this.subSystemModalRef.hide();

    const atLeastOneCalculationRelevantError = this.hasAtLeastOneCalcRelevantFieldsChanged();

    if (atLeastOneCalculationRelevantError) {
      this.configurationService.recalculate(true);
    }
  }

  private setLanguage(languageId: string): void {
    const langId = languageId?.toLowerCase();
    debugger;
    if (!langId) {
      this.translate.use('en');
      this.inputModel.contactCountry = 'United Kingdom of Great Britain and Northern Ireland (the)';
      return;
    }

    switch (langId) {
      case 'de':
        this.translate.use('de');
        this.inputModel.contactCountry = 'Germany';
        break;
      case 'it':
        this.translate.use('it');
        this.inputModel.contactCountry = 'Italy';
        break;
      case 'enimp':
        this.inputModel.contactCountry = 'United States of America (the)';
        break;
      default:
        this.translate.use('en');
        this.inputModel.contactCountry = 'United Kingdom of Great Britain and Northern Ireland (the)';
    }

  }

  private labelYes: string = null;
  private labelNo: string = null;

  constructor(private priceService: PricesService, private configurationService: ConfigurationService, public translate: TranslateService, private modalService: BsModalService,
    private route: ActivatedRoute, @Inject(DOCUMENT) private document: Document, private userService: UserService, private authService: AuthService, private clipboardService: ClipboardService) {

    const langId = route.snapshot.queryParams["lang"];

    this.inputModel = InputModelMapper.getDefaultInputModel();

    this.setLanguage(langId);

      
    //this.form.push(this.contact); //this.contact, this.generalInput, this.systemConfig, this.inputSafety, this.inputSoilParameterForm, this.inputMainFieldsForm
    //this.form.push(this.generalInput);
    //this.form.push(this.systemConfig);
    //this.form.push(this.inputSafety);
    //this.form.push(this.inputSoilParameterForm);
    //this.form.push(this.inputMainFieldsForm);

    
    this.inputContactModelChange();
    this.initOptionLabelTranslation();
    this.currentCalculationCode = new FormControl('');
    this.codeLoaded = false;
    this.selectConfigForm.get('selectedGroup').valueChanges.subscribe(newValue => {

      const configGroup = (newValue as IConfigurationGroup);

      const calcTitleToSet = !configGroup ? null : configGroup.subCalculations.find(x => x.isDefault);
      this.availableCalculations = !configGroup ? [] : configGroup.subCalculations;
      this.selectConfigForm.get('selectedCalculation').setValue(calcTitleToSet);
    });

    this.configurationService.currentResultSet.subscribe(newResult => {
      this.productPrices = newResult ? newResult.productPriceDetails : [];
      this.rowData = newResult ? newResult.resultLayers : [];
    });

    this.configurationService.currentCalculationCode.subscribe(newCode => {
      this.currentCalculationCode.setValue(newCode ? newCode[1] : null);
      this.codeLoaded = !!newCode;
      this.inputModel.id = newCode ? newCode[0] : null;

      if (this.codeLoaded) {
        this.currentCalculationCode.disable();
      } else {
        this.currentCalculationCode.enable();
      }
    });

    this.selectConfigForm.get('selectedCalculation').valueChanges.subscribe(newValue => {
      const selectedConfig = (newValue as IConfigurationTitle);

      this.configurationService.refreshCalculationById(selectedConfig ? selectedConfig.id : null);
      
    });

    this.configurationService.currentGroups.subscribe(groups => {
      this.availableConfigGroups = groups;
    });

    this.configurationService.currentCalculation.subscribe(newCalc => {
      if (newCalc) {

        const newModel = InputModelMapper.getInputModel(newCalc);
        //this.inputModel = { ...newModel };
        const role = this.inputModel.role;
        const lang = this.inputModel.language;
        this.inputModel = newModel;
        this.inputModel.role = role;
        this.inputModel.language = lang;
        this.inputContactModelChange();
        this.configurationService.setNewInputModel(this.inputModel);
        this.configurationService.recalculate(true);
      }

    });

    this.columnDefs = [
      { headerName: 'Layer/Lage [-]', field: 'layerIndex', resizable: true },
      { headerName: 'Height above base/Höhe [m]', field: 'height', resizable: true },
      { headerName: 'Type [-]', field: 'productLine.label', resizable: true },
      { headerName: 'Length/Länge [m]', field: 'resultTotalLength', resizable: true },
      { headerName: 'Price/Preis [€/m²]', field: 'productLine.price', resizable: true }
      //{ headerName: 'Coordinates BL', field: 'coordinates.val_1' },
      //{ headerName: 'Coordinates BM', field: 'coordinates.val_2' },
      //{ headerName: 'Coordinates BN', field: 'coordinates.val_3' },
    ];

    this.modelChange(null);
  }

  ngOnInit(): void {

    //this.document.documentElement.lang = 'de';
    this.route.queryParamMap.subscribe(params => {
      const langId = (<any>params).params.langId;

      if (!!langId) {
        this.setLanguage(langId);
      }

    });
    this.isLoggedInSubscription = this.authService.isLoggedIn.subscribe(loggedInState => this.isLoggedIn = loggedInState);
    this.roleSubscription = this.userService.currentRole.subscribe(role => {
      this.inputMainFieldsForm.patchValue({ "role": role });
      this.inputModel.role = role;
    });
  }

  inputContactModelChange() {
    this.canSaveConfig = !this.inputModel.moreInformationNeeded || !!this.inputModel.emailAddress;
  }

  private calcRelevantFields = ['frictionAngleCharacteristic', 'unitWeightCharacteristic', 'phValueOfFillingSoil', 'designLife', 'frictionAngle',
    'unitWeight', 'trafficLoad', 'materialPartialSafetyFactor', 'productString', 'uniformVerticalSurcharge', 'effectiveHeight', 'slopeAngle', 'verticalLayerDistance',
    'grainSizeDistribution', 'priceId', 'systemModelNature', 'seedingMethod', 'systemModelGabion', 'systemModelBlock', 'constructionMethodGabion', 'subsystemGabion', 'height',
    'selectedSystem', 'slopeAngle', 'shouldAllGeoGridsHaveSameLength', 'petPva'];


  private isValidationErrorCalculationRelevant(): boolean {
    const controls = this.form.controls.map(x => (<any>x).controls).reduce((prev, current) => {
      if (Object.keys(current).length > 0) {

        Object.keys(current).forEach(x => prev.push({ name: x, ctrl: current[x] as FormControl}));
      }

      return prev;
    }, []);

    const validationErrorsForCalcRelevantFields = controls.filter(x => {
      const ctrl = x.ctrl as FormControl;
      return !(ctrl.errors === null || !this.calcRelevantFields.find(y => y === x.name));
    });

    return validationErrorsForCalcRelevantFields.length > 0;
  }

  private showSubsystemSelectionDialog(): boolean {
    const oldconfig = this.oldConfig;
    const newConfig = this.inputModel;

    if (!newConfig || !oldconfig) {
      return false;
    }

    
    if (oldconfig.selectedSystem === newConfig.selectedSystem) {
      return false;
    }

    return newConfig.selectedSystem === Product.Block ||
      newConfig.selectedSystem === Product.Gabion ||
      newConfig.selectedSystem === Product.Nature;
  }

  private hasAtLeastOneCalcRelevantFieldsChanged(): boolean {
    const oldCfg = this.oldConfig;
    const newCfg = this.inputModel;

    if (!oldCfg || !newCfg) {
      return true;
    }

    const res = this.calcRelevantFields.filter(x => {
      if (!Object.keys(oldCfg).find(y => x === y)) {
        return false;
      }

      return oldCfg[x] !== newCfg[x];
    });

    return res.length > 0;
  }

  oldConfig: InputModel;

  private checkAndSetSystemOrSubSystemChange(system: string, subSystem: string): void {
    

    if (this.selectedSystemAndSubSystem === null) {
      this.selectedSystemAndSubSystem = {
        system: system,
        subSystem: subSystem
      };
    } else {
      if (this.selectedSystemAndSubSystem.system !== system || this.selectedSystemAndSubSystem.system !== subSystem) {
        this.selectedSystemAndSubSystem = {
          system: system,
          subSystem: subSystem
        };
      }
    }

    console.log(this.selectedSystemAndSubSystem);
  }

  private gridApi;

  onGridReady($event) {
    this.gridApi = $event.api;
    this.gridApi?.setColumnDefs(this.columnDefs);
  }

  selectedProductInTable:string;

  productSelected($event) {
    this.selectedProductInTable = $event;
    //console.log(this.selectedProductInTable);
  }

  modelChange($event) {
    
    if (this.oldConfig) {
      const hasRoleChanged = this.inputModel.role !== this.oldConfig.role;
      console.log('Role changed. New role:' + this.inputModel.role);
      if (hasRoleChanged) {
        this.userService.changeRole(this.inputModel.role);
      }

      // const haslUnitSystemChanged = this.inputModel.unitSystem !== this.oldConfig.unitSystem;

      // if (haslUnitSystemChanged) {
      //   const lang = this.inputModel.unitSystem === 1 ? 'en' : 'enimp';
      //   this.inputModel.language = lang;
      //   this.translate.use(lang);
      //   this.reset();
      // }


      if (this.inputModel.role === UserRoles.partner) {
        const elementToRemove = this.columnDefs.find(x => x.field === 'productLine.price');
        if (elementToRemove) {
          const index = this.columnDefs.indexOf(elementToRemove);
          this.columnDefs.splice(index, 1);

          this.gridApi?.setColumnDefs(this.columnDefs);
        }
      } else {
        const isExisting = !!this.columnDefs.find(x => x.field === 'productLine.price');

        if (!isExisting) {
          this.columnDefs.push({ headerName: 'Price [€/m²]', field: 'productLine.price' });
          this.gridApi?.setColumnDefs(this.columnDefs);
        }
      }
    }



    const atLeastOneCalculationRelevantError = this.isValidationErrorCalculationRelevant();
    const atLeastOneRelevantFieldChanged = this.hasAtLeastOneCalcRelevantFieldsChanged();

    this.configurationService.setNewInputModel(this.inputModel);

    if (atLeastOneRelevantFieldChanged) {
      this.configurationService.recalculate(!atLeastOneCalculationRelevantError);
    }

    const showSubsystemSelection = this.showSubsystemSelectionDialog();

    if (showSubsystemSelection) {
      this.showSubsystemSelection(this.templateSubsystem);
    }

    this.oldConfig = JSON.parse(JSON.stringify(this.inputModel)) as InputModel;

    this.subSystemName = this.getSubsystemName();

    let subSystem = null;

    switch (this.inputModel.selectedSystem) {
      case Product.Nature:
        subSystem = this.inputModel.systemModelNature.toString();
        break;
      case Product.Gabion:
        subSystem = this.inputModel.systemModelGabion.toString();
        break;
      case Product.Block:
        subSystem = this.inputModel.systemModelBlock.toString();
        break;
    }

    this.checkAndSetSystemOrSubSystemChange(this.inputModel.selectedSystem.toString(), subSystem);
  }

  isCreatingButtonProcessing = false;

  @ViewChild('templatesubsystem', { read: TemplateRef}) templateSubsystem:TemplateRef<any>;

  showSubsystemSelection(template): void {
    
    this.selectedSubSystemId = null;
    this.subSystemModalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  createNewCalculation(template): void {

    const isUpdate = !!this.inputModel.id && !!this.currentCalculationCode.value;

    this.isCreatingButtonProcessing = true;

    this.dataPrivacyAccepted = false;

    const showModal = () => {
      this.isCreatingButtonProcessing = false;
      
      this.modalRef = this.modalService.show(template);

    }

    const config = InputModelMapper.getConfig(this.inputModel, this.inputModel.id, null, this.currentCalculationCode.value);
    config.sendEmail = false;

    if (isUpdate) {
      this.configurationService.updateConfiguration(config,
        () => {
          showModal();
        });
    } else {
      this.configurationService.createNewConfiguration(config, (code) => {
        showModal();
      });
    }


  }

  sendEmail(): void {
    if (!
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
        .test(this.contact.value.emailAddress)) {
      alert("Bitte eine gültige Emailadresse eintragen.");
      return;
    }

    debugger;

    const config =
      InputModelMapper.getConfig(this.inputModel, this.inputModel.id, null, this.currentCalculationCode.value);
    config.sendEmail = true;
    config.languageCode = this.translate.currentLang;

    this.isCreatingButtonProcessing = true;

    this.configurationService.updateConfiguration(config, () => {
      this.isCreatingButtonProcessing = false;
      this.modalRef.hide();
    });
    
  }

  updateNewCalculation():void {
    //const config =
    //  InputModelMapper.getConfig(this.inputModel, this.inputModel.id, null, this.currentCalculationCode.value);
    //this.configurationService.updateConfiguration(config);
  }

  isUpdateButtonVisible: boolean ;
  isCreateButtonVisible: boolean = true;

  selectedSystemAndSubSystem = null;

  createnewSubCalculation(): void {
    const group = this.selectConfigForm.get('selectedGroup').value as IConfigurationGroup;
    const masterId = group.subCalculations.find(x => x.isDefault).id;


    this.configurationService.createNewSubConfiguration(masterId, "New subcalculation");
  }

  dataPrivacyChanged() {
    this.dataPrivacyAccepted = !this.dataPrivacyAccepted;
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    console.log(changeEvent);
    if (changeEvent.nextId === 10) {
      changeEvent.preventDefault();
      this.openLoginModal();
    }
    if (changeEvent.nextId === 11) {
      changeEvent.preventDefault();
      this.signOut();

    }
  }

  openLoginModal(): void {
    this.signInComponent.show();
  }

  signOut(): void {
    this.authService.signOut();
    this.ngbNav.select(1);  // Select first tab item.
  }
}
